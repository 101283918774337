export const GTM_ID = {
  Investor: 'GTM-TT7MNQG',
  Advisor: 'GTM-5PK9DDM'
}
export const ISSUE_TYPES = {
  Etf: 'ETF',
  MutualFund: 'MUTUALFUND',
  Indices: 'INDICES',
  Stock: 'STOCK'
}
export const ISSUE_TYPES_MAP = {
  ADR: 'STOCK',
  ALN: 'STOCK',
  CL: 'STOCK',
  CLRT: 'STOCK',
  CP: 'STOCK',
  CPRT: 'STOCK',
  CS: 'STOCK',
  CSRT: 'STOCK',
  DR: 'STOCK',
  DRRT: 'STOCK',
  EQ: 'STOCK',
  ETDS: 'STOCK',
  IDR: 'STOCK',
  PR: 'STOCK',
  PRRT: 'STOCK',
  PS: 'STOCK',
  RT: 'STOCK',
  WD: 'STOCK',
  WI: 'STOCK',
  CSD: 'STOCK',
  ETF: 'ETF',
  LETF: 'ETF',
  ETN: 'ETF',
  LETN: 'ETF',
  MF: 'MUTUALFUND',
  OF: 'MUTUALFUND',
  IN: 'INDICES'
}
export const NEWS_CATEGORIES = [
  {'Key': 'Top News', 'Value': 'Top News'},
  {'Key': 'U.S.', 'Value': 'US'},
  {'Key': 'World', 'Value': 'World'},
  {'Key': 'Economy', 'Value': 'Economy'},
  {'Key': 'Business', 'Value': 'Business'},
  {'Key': 'Tech', 'Value': 'Tech'},
  {'Key': 'Politics', 'Value': 'Politics'}
]

export const API_END_POINTS = {
  QUOTE: '/SnapshotQuote',
  ETF_FUND: '/xref-etp-indicators/v1/fund-indicators/',
  QUOTE_PLATFORM: '/quote/v1/details',
  OPTION_CHAIN_PLATFORM: 'optionchains/v1/options/chainwithquotes/',
  OPTION_CHAIN_EXPIRATION_DATES_PLATFORM: '/optionchains/v1/options/expirationdates/',
  GENERAL_INFO: '/CompanySummary',
  TOP_HOLDINGS_PLATFORM: '/managedfunds-morningstar-topholdings/v1/topHoldings/',
  PROSPECTUS_FEES_PLATFORM: '/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/',
  SHARE_CLASS_INFO_PLATFORM: '/managedfunds-morningstar-shareclass/v1/share-class/',
  TRAILING_MONTH_END_PLATFORM: '/managedfunds-morningstar-performance/v1/trailing-month-end/',
  SNAPSHOT_INVESTMENT_BASICS: '/MutualFundSnapshotInvestmentBasics',
  MUTUALFUND_10K_GROWTH_CHART: '/MutualFundSnapshotChart',
  XID_FROM_SYMBOL_PLATFORM: '/xref/v1/symbols/',
  NEWS: '/News',
  MF_NEWS: '/MFNews',
  NEWS_ARTICLE: '/NewsArticle',
  NEWS_IMAGE: '/newsImage',
  MARKET_STREASURY_YIELD: '/MarketsTreasuryYield',
  MARKETS_RANK_ALL: '/marketsRankAll',
  WATCHLIST_PLATFORM: '/watchlist/v1/watchlist',
  REVENUE_CHART: '/revenueChart',
  DIVIDEND_CHART: '/dividendChart',
  QUOTE_INDICE: '/marketsQuote',
  WATCHLIST_PERFORMANCE: '/watchlistPerformance',
  EQUITY_DIVIDEND: '/equityDividend'
}

export const INDICES_SYMBOL = {
  DJIA: 599362,
  NASDAQ: 579435,
  NYSE: 566677,
  SP500: 593933
}

export const detectEnvByDomain = (() => {
  const {
    location: { hostname = 'stifel.markitondemand.com' }
  } = window

  const envs = {
    localhost: 'DEV',
    '127.0.0.1': 'DEV',
    'stifel.markitqa.com': 'ACC',
    'stifel-a2.markitqa.com': 'ACC2'
  }

  return envs[hostname] || 'PROD'
})()

export const WEBSITE_DOMAIN = {
  DEV: 'http://localhost:3001',
  ACC: 'https://stifel.markitqa.com',
  ACC2: 'https://stifel-a2.markitqa.com',
  PROD: 'https://stifel.markitondemand.com'
}

export const SSO_DOMAIN = {
  DEV: 'https://sso-mod.markitqa.com',
  ACC: 'https://sso-mod.markitqa.com',
  ACC2: 'https://sso-mod.markitqa.com',
  PROD: 'https://sso.markitondemand.com'
}

export const STIFEL_SSO_DOMAIN = {
  DEV: 'dev-01.q2developer.com',
  ACC: 'temporary.q2ebanking.com',
  ACC2: 'temporary.q2ebanking.com',
  PROD: 'tracker.stifel.com'
}

export const MAX_WATCHLISTS = 6
export const MAX_SECURITIES = 50
export const MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE = 1000

export const SORTING_ORDER = {
  ASCENDING: 'ASC',
  DECENDING: 'DESC'
}

export const WATCHLIST_MESSAGES = {
  DUPLICATE: 'Watchlist already exists. Please enter a different name.',
  MAX_SECURITIES: `The maximum number of ${MAX_SECURITIES} symbols per Watchlist has been reached.`,
  MAX_WATCHLISTS: `The maximum number of ${MAX_WATCHLISTS} Watchlist has been reached.`,
  DELETE_SYMBOL_CONFIRMATION: (symbol) => {
    return `Are you sure you want to delete "${symbol}" from your Watchlist?`
  },
  DELETE_WATCHLIST_CONFIRMATION: (watchlist) => {
    return `Are you sure you want to delete "${watchlist}" Watchlist?`
  },
  GENERIC: 'We are not able to save changes at this time. Please try again later.',
  ADDED_SYMBOL: (symbol) => {
    return `"${symbol}" added to Watchlist.`
  }
}

export const WATCHLIST_CONSTANTS = {
  WATCHLIST_OVERVIEW: 'Watchlist Overview'
}

export const NEWS_CONSTANTS = {
  LAST_N_DAYS_NEWS: 14
}

export const DATE_TIME_CONSTANTS = {
  AS_OF_PREFIX: 'As of',
  AS_OF_DATE_FORMAT: 'MM/DD/YYYY',
  AS_OF_DATE_TIME_FORMAT: 'MM/DD/YYYY, hh:mm A'
}

export const SNAPSHOT_WSODISSUE_CONSTANTS = 'wsodIssue'
export const SNAPSHOT_SYMBOL_CONSTANTS = 'symbol'
export const SNAPSHOT_ACTIVETAB_CONSTANTS = 'activeTab'
export const SNAPSHOT_NEWS_CONSTANTS = 'news'
export const WATCHLISTS_WATCHLISTTAB_CONSTANTS = 'watchlistTab'
export const WATCHLISTS_SELECTED_CONSTANTS = 'selectedWatchList'
export const LOCALSTORAGETYPE_CONSTANTS = {
  MARKETNEWS: 'MarketNews',
  WATCHLISTS: 'Watchlists'
}
export const SHOW_DIVIDEND_DATA = false